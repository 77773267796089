<template>
<div>
    <div class="nurseOffice mt10" v-loading="loadingData">
        <div class="mt10 mb-3">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/hospital' }">{{ $t('message.hospital') }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $t('message.phy_office') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="nurseOffice_tabs">
            <el-card class="box-card mb-4 pl-3 pr-3 pt-2">
            <!-- <el-row :gutter="20">
                <el-col :span="24">Поиск Пациент</el-col>
            </el-row>-->
                <el-form ref="form" :model="form" class="aticler_m p-0 mt-4" size="small">
                    <el-row :gutter="15">
                        <el-col :xs="12" :sm="8" :md="6" :lg="6">
                            <el-form-item :label="$t('message.number_card')">
                                <el-input suffix-icon="el-icon-search" v-model="number" :placeholder="$t('message.number_card')" v-mask="'### ### ###'"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="12" :sm="8" :md="6" :lg="6">
                            <el-form-item :label="$t('message.patient')">
                                <span class="patsent_namber">{{ patientHistory.hospitalPatient? patientHistory.hospitalPatient.first_name+' '+patientHistory.hospitalPatient.surname+' '+ (patientHistory.hospitalPatient? patientHistory.hospitalPatient.patronymic : '')  : '' }}</span>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="12" :sm="8" :md="6" :lg="6">
                            <el-button class="butt_sacrch" style="width: 200px; margin-top: 25px" type="success" icon="el-icon-search" size="medium" @click="search()" round>{{ $t('message.search_0') }}</el-button>
                        </el-col>
                        <div v-if="patientHistory.is_ended != 0 && patientHistory.is_ended" class="left_textLastHistory_off ">{{ $t('message.hospitalization_complated') }}! <span><b>{{ $t('message.patient') }}:</b> {{patientHistory.endedUser ? patientHistory.endedUser.name : ''}} {{patientHistory.endedUser ? patientHistory.endedUser.surname : ''}} {{patientHistory.endedUser ? patientHistory.endedUser.patronymic : ''}}</span></div>
                    </el-row>

                </el-form>
            </el-card>
            <template v-if="this.number == this.patientHistory.number">
                <el-tabs type="border-card">
                    <el-tab-pane :label="$t('message.physiotherapy')">
                        <Physiotherapy :number="number" :isended="num"/>
                    </el-tab-pane>
                </el-tabs>
            </template>
        </div>
    </div>
</div>
</template>

<script>
import Physiotherapy from "./components/physiotherapy";
import {
    mapGetters,
    mapActions
} from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
    mixins: [list, drawer],
    name: "nurseOfficePhy",
    components: {
        Physiotherapy,
    },
    data() {
        return {
            patientHistory: {},
            hospitalPatient: {},
            number: '',
            num: '',
            form: {},
            loadingData: false,
            selectedModel: {},
            drawer: {
                create: {
                    name: "create",
                    status: false,
                    component: 'componentDrawerCreate'
                }
            }
        }
    },
    computed: {
        getId() {
            return this.$route.params.id;
        },
        ...mapGetters({
            activeCode: 'hospitalPatients/activeCode',
            eventfilter: 'hospitalPatients/filter',
            list: 'nurceOfficePhies/list',
            columns: "nurceOfficePhies/columns",
            pagination: "nurceOfficePhies/pagination",
            filter: "nurceOfficePhies/filter",
            sort: "nurceOfficePhies/sort",

        }),
        actions: function () {
            return ['edit', 'delete'];
        }
    },
    async mounted() {

    },
    methods: {
        ...mapActions({
            updateList: 'nurceOfficePhies/index',
            updateSort: "nurceOfficePhies/updateSort",
            updateFilter: "nurceOfficePhies/updateFilter",
            updateColumn: "nurceOfficePhies/updateColumn",
            updatePagination: "nurceOfficePhies/updatePagination",
            editModel: 'nurceOfficePhies/showItem',
            showNumber: 'nurceOfficePhies/showNumber',
            empty: 'nurceOfficePhies/empty',
            delete: 'nurceOfficePhies/destroy',
            refreshData: 'nurceOfficePhies/refreshData',
        }),
        search() {
            this.loadingData = true;
            this.showNumber({
                    number: this.number
                })
                .then(res => {
                    this.loadingData = false;
                    this.patientHistory = res.data.patientHistory;
                    this.num = res.data.patientHistory.is_ended;
                }).catch(err => {
                    this.loadingData = false;
                    this.$alert(err)
                });
        },
        fetchData() {},
        refresh() {
            this.refreshData()
                .then(res => {
                    this.filterForm = JSON.parse(JSON.stringify(this.filter));
                })
                .catch(err => {

                })
        },
        destroy(model) {
            this.delete(model.id)
                .then(res => {
                    this.$alert(res);
                    this.fetchData()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        emptyModel() {
            this.empty()
        },
    }
};
</script>

<style lang="scss">
.patsent_namber {
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    /* height: 40px; */
    /* line-height: 40px; */
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
    height: 32px;
    line-height: 29px;
    font-size: 14px;
    cursor: not-allowed;
    background: #efefefc9;
}
</style>
